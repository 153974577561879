'use client'

import { Button, ButtonProps } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { ExitIcon } from '@radix-ui/react-icons'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next-nprogress-bar'
import { useState } from 'react'
import { css } from 'styled-system/css'

import { BlockPubAccountCustomPageData, BlockViewProps } from '../../../_utils/typescript-utils'
import { AccountPropay } from './AccountPropay/AccountPropay'
import { AccountStripe } from './AccountStripe/AccountStripe'

export const BlockPubAccount: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubAccountCustomPageData
  }>
> = props => {
  const [signOutBtnStatus, setSignoutBtnStatus] = useState<ButtonProps['buttonState']>('default')
  const { data: session, status } = useSession()
  const router = useRouter()

  const onSignOut = async (redirectUser = false) => {
    try {
      setSignoutBtnStatus('waiting')
      // TODO: test if this is a better way to sign out. Tried but didn't yet manage on dev
      // https://next-auth.js.org/getting-started/client#using-the-redirect-false-option-1
      const url = new URL(location.href)
      url.pathname = props.blockCustomData.notAuthRedirectUrl
      await signOut({ redirect: false, callbackUrl: url.toString() })
      if (redirectUser) {
        router.push(props.blockCustomData.notAuthRedirectUrl)
      }
    } finally {
      setSignoutBtnStatus('default')
    }
  }

  if (status === 'unauthenticated') {
    router.push(props.blockCustomData.notAuthRedirectUrl)
    return null
  }

  return (
    <Gutter
      css={{ bgColor: '$gs1' }}
      variant="tall"
      data-sho-block-name="BlockPubAccount"
      data-sho-block-id={props.blockId}>
      <CustomFlex direction={{ base: 'column', bp1: 'row' }} align={{ base: 'center', bp1: 'start' }}>
        <CustomFlex direction="column" align={{ base: 'center', bp1: 'start' }}>
          <Text variant="h2">Account</Text>
          <Text variant="body1" css={{ mt: '$4', color: '$gs11' }}>
            Manage your profile and subscriptions here.
          </Text>
        </CustomFlex>
        <Button
          css={signOutButton}
          variant="secondary"
          fullWidth={true}
          label="Sign Out"
          buttonState={signOutBtnStatus}
          reactIcon={<ExitIcon />}
          onClick={() => onSignOut(true)}
        />
      </CustomFlex>
      {props.blockCustomData.connectedSubscriptionSystem === 'PROPAY' && <AccountPropay {...props} />}
      {props.blockCustomData.connectedSubscriptionSystem === 'STRIPE' && <AccountStripe {...props} />}
    </Gutter>
  )
}

const signOutButton = css.raw({
  mt: '$4',
  bp1: {
    mt: '$0',
    ml: '$12',
    width: 'fit!',
  },
  bp2: {
    ml: '$0',
  },
})
