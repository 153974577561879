'use client'

import { ArticleAnalytics, PropsForAnalytics } from 'app/sites/[site]/[[...page]]/helper-functions/getPropsForSeo'
import { usePathname, useSearchParams } from 'next/navigation'
import { FC, Suspense } from 'react'

const AnalyticsPixel: FC<{
  siteId: string
  path: string
  referrer: string
  data: Record<string, string | undefined | null> | null
}> = ({ siteId, data, path, referrer }) => {
  // remove undefined values
  if (data) {
    Object.keys(data).forEach(key => (data[key] === undefined || data[key] === null) && delete data[key])
  }
  const searchParam = new URLSearchParams({ sid: siteId, p: path, r: referrer, ...data })
  const analyticsUrl = `https://pubgen-analytics.mathis-73e.workers.dev/collect`
  // this is a hack to use the image as a tracking pixel
  return (
    <img alt="" aria-hidden="true" style={{ position: 'absolute' }} src={`${analyticsUrl}?${searchParam.toString()}`} />
  )
}
const TrackArticleView: FC<
  {
    path: string
    referrer: string
  } & ArticleAnalytics
> = ({ siteId, path, referrer, section, author, releaseDate, title, blockId }) => {
  return AnalyticsPixel({
    siteId,
    path,
    referrer,
    data: {
      title,
      section,
      author,
      releaseDate,
      blockId,
    },
  })
}

const TrackPageViewComponent: FC<{
  path: string
  referrer: string
  siteId: string
}> = ({ path, referrer, siteId }) => {
  return AnalyticsPixel({ siteId, path, referrer, data: null })
}

const TrackPageView: FC<{ propsForAnalytics: PropsForAnalytics }> = ({ propsForAnalytics }) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const path = pathname + searchParams.toString()
  const siteId = propsForAnalytics.siteId
  // TODO: find a way to get referrer without getting a dom mismatch error from react
  // const refferer = typeof window !== 'undefined' ? document.referrer : ''
  const referrer = ''
  if (propsForAnalytics.type === 'article') {
    return (
      <>
        <TrackArticleView path={path} {...propsForAnalytics} />
      </>
    )
  }
  return (
    <>
      <TrackPageViewComponent siteId={siteId} path={path} referrer={referrer} />
    </>
  )
}

export const Analytics: FC<{ propsForAnalytics: PropsForAnalytics | undefined }> = ({ propsForAnalytics }) => {
  if (!propsForAnalytics) return null
  return (
    <Suspense fallback={<></>}>
      <TrackPageView propsForAnalytics={propsForAnalytics} />
    </Suspense>
  )
}
