import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { Text } from '@design-system/src/components/Text'
import Link from 'next/link'
import { FC } from 'react'
import { css, cva } from 'styled-system/css'

type SectionAndDatePreviewProps = {
  sectionName: string
  sectionHref?: string
  date: string
  wrapSectionWithLink?: boolean
  css?: object
  colorVariant: 'light' | 'color'
}

export const SectionAndDatePreview: FC<SectionAndDatePreviewProps> = ({
  sectionName,
  sectionHref,
  date,
  css: cssProp = {},
  wrapSectionWithLink = true,
  colorVariant,
}) => (
  <div className={css({ gap: '$2', ...cssProp })}>
    {wrapSectionWithLink && sectionHref ? (
      <Link href={sectionHref} prefetch={true}>
        <Text
          variant="overline"
          isLink={true}
          className={sectionTextWithLink({
            colorVariant,
          })}>
          {sectionName}
        </Text>
      </Link>
    ) : (
      <Text variant="overline" className={sectionTextNoLink({ colorVariant })}>
        {sectionName}
      </Text>
    )}
    <CustomDivider orientation="vertical" size="small" className={dividerStyled({ colorVariant })} />
    <Text variant="overline" className={dateText({ colorVariant })}>
      {date}
    </Text>
  </div>
)

const dividerStyled = cva({
  base: {
    height: '[1em]',
    display: 'inline-block',
    ml: '$2',
    mr: '$2',
  },
  variants: {
    colorVariant: {
      light: {
        backgroundColor: '$gs6',
      },
      color: {
        backgroundColor: '$pri',
      },
    },
  },
})

const sectionTextNoLink = cva({
  base: {
    display: 'inline',
    color: '$pri',
    lineHeight: '[1em]',
  },
  variants: {
    colorVariant: {
      light: {
        color: '$gs1',
      },
      color: {
        color: '$pri',
      },
    },
  },
})

const sectionTextWithLink = cva({
  base: {
    display: 'inline',
    lineHeight: '[1.25em]',
    _hover: {
      transitionProperty: 'all',
      transitionDuration: '$normal',
      transitionTimingFunction: 'in-out',
    },
  },
  variants: {
    colorVariant: {
      light: {
        color: '$gs1',
        '&:hover': {
          opacity: '0.8',
        },
      },
      color: {
        color: '$pri',
        '&:hover': {
          color: '$pri_L',
        },
      },
    },
  },
})

const dateText = cva({
  base: {
    display: 'inline',
    color: '$gs11',
    lineHeight: '[1em]',
    textWrap: 'nowrap',
  },
  variants: {
    colorVariant: {
      light: {
        color: '$gs1',
      },
      color: {
        color: '$gs11',
      },
    },
  },
})
