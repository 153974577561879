'use client'

import { ButtonProps } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { valid6DigitCode } from '@design-system/src/utils/validation-utils'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { CustomSubmitBtn } from './CustomSubmitBtn'

interface IFormInputCode {
  code: string
}

export const SignInCode: FC<{
  submittedEmail: string | null
  customerServiceNumber?: string
}> = ({ submittedEmail, customerServiceNumber }) => {
  const [submitBtnStateCode, setSubmitBtnStateCode] = useState<ButtonProps['buttonState']>('default')
  const [showError, setShowError] = useState(false)
  const {
    handleSubmit: handleSubmitCode,
    control: controlCode,
    formState: { errors: errorsCode },
  } = useForm<IFormInputCode>({ mode: 'onSubmit' })

  const onSubmitCode: SubmitHandler<IFormInputCode> = async formData => {
    setShowError(false)
    setSubmitBtnStateCode('waiting')
    const code = formData.code
    try {
      const url = new URL(location.href)
      url.pathname = '/'
      // submit code
      window.location.href =
        '/api/auth/callback/email?callbackUrl=' + url.toString() + '&token=' + code + '&email=' + submittedEmail
    } catch (error) {
      console.error('error signin in:', error)
      setShowError(true)
    } finally {
      setSubmitBtnStateCode('default')
    }
  }

  return (
    <Gutter css={{ paddingLeft: '$0 !important' }}>
      <form method="post" onSubmit={handleSubmitCode(onSubmitCode)}>
        <CustomFlex direction="column" gap={'4'}>
          <Text css={{ color: '$gs12', mb: '$8' }} variant="subtitle1">
            We just emailed a code to <b>{submittedEmail}</b>. Once it arrives, it will be valid for 24 hours.
            {customerServiceNumber ? ' Call the number below if not received.' : ''}
          </Text>
          <Controller
            name="code"
            control={controlCode}
            defaultValue=""
            rules={{
              required: 'Code is required',
              pattern: {
                value: valid6DigitCode,
                message: 'Please enter a valid code.',
              },
            }}
            render={({ field }) => (
              <Textfield
                {...field}
                autoFocus={true}
                fullWidth={true}
                css={{ textAlign: 'center' }}
                placeholder="* Enter your code"
                type="number"
                name="code"
                reactFormErrors={errorsCode}
              />
            )}
          />
          <CustomSubmitBtn disabled={submitBtnStateCode === 'waiting'} label="Sign In with Code" />
        </CustomFlex>

        {showError && (
          <CustomFlex
            css={{ backgroundColor: '$ale', mt: '$4', py: '$2', borderRadius: '$3' }}
            justify="center"
            align="center">
            <Text css={{ color: '$aleText', textAlign: 'center' }} variant="caption">
              The code provided is not recognized.
            </Text>
          </CustomFlex>
        )}
      </form>
    </Gutter>
  )
}
