import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import React, { useState } from 'react'

import { BlockModal } from '../../../BlockModal'
import { CancelationFormInput } from './CancelationReasonModal'

type SpecialOfferModalProps = {
  open: boolean
  setOpen: (value: boolean) => void
  discountPromotionalText?: string
  cancelSubscriptionApiUrl: string
  applyDiscountApiUrl?: string
  setSubscriptionCancelledModalOpen: (value: boolean) => void
  subscriptionId?: string
  feedbackFormReason?: CancelationFormInput
  discountCode?: string
  discountApplicationSuccessText?: string
}

export const CancelSubscriptionModal: React.FC<SpecialOfferModalProps> = props => {
  const { update } = useSession()
  const {
    open,
    setOpen,
    discountPromotionalText,
    applyDiscountApiUrl,
    setSubscriptionCancelledModalOpen,
    subscriptionId,
    feedbackFormReason,
    discountCode,
    discountApplicationSuccessText,
    cancelSubscriptionApiUrl,
  } = props

  const [failedToCancel, setFailedToCancel] = useState(false)
  const [failedToApply, setFailedToApply] = useState(false)
  const [callInProgress, setCallInProgress] = useState(false)
  const [confirmDiscountModalOpen, setConfirmDiscountModalOpen] = useState(false)

  const onCloseModal = () => {
    setOpen(false)
    setFailedToCancel(false)
    setFailedToApply(false)
  }

  const onCancelSubscriptionClick = async () => {
    setCallInProgress(true)
    const data = new URLSearchParams()
    console.log('subscriptionId', subscriptionId)
    try {
      data.append('subscriptionId', subscriptionId || '')
      if (feedbackFormReason) {
        let key: keyof typeof feedbackFormReason
        for (key in feedbackFormReason) {
          if (feedbackFormReason.hasOwnProperty(key)) {
            const value = feedbackFormReason[key]
            if (typeof value === 'boolean' && value) {
              data.append('reason', key)
            } else if (typeof value === 'string') {
              data.append('reason', value)
            }
          }
        }
      }
      const res = await fetch(cancelSubscriptionApiUrl, {
        method: 'POST',
        body: data,
      })
      if (res.status !== 200) {
        throw new Error('Error canceling subscription.')
      }
      await update()
      onCloseModal()
      setSubscriptionCancelledModalOpen(true)
    } catch (error) {
      setFailedToCancel(true)
      console.error(
        'Failed to cancel. subscriptionId, feedbackFormReason, cancelSubscriptionApiUrl:',
        subscriptionId,
        feedbackFormReason,
        cancelSubscriptionApiUrl,
      )
    } finally {
      setCallInProgress(false)
    }
  }

  const onApplyDiscountBtnClick = async () => {
    if (!applyDiscountApiUrl) {
      console.error('applyDiscountApiUrl is not defined.')
      return
    }
    const data = new URLSearchParams()
    try {
      data.append('subscriptionId', subscriptionId || '')
      data.append('discount', discountCode || '')
      const res = await fetch(applyDiscountApiUrl, {
        method: 'POST',
        body: data,
      })
      if (res.status !== 200) {
        throw new Error('Error in applying discount.')
      }
      onCloseModal()
      setConfirmDiscountModalOpen(true)
    } catch (error) {
      setFailedToApply(true)
      console.log('Failed to apply.')
    }
  }

  const modalTitle = applyDiscountApiUrl ? "Don't Go! Check Out This Special Offer!" : 'Cancel Subscription'
  const cancelSubscriptionCta = applyDiscountApiUrl ? 'No, Cancel Subscription' : 'Confirm Cancelling Subscription'

  return (
    <>
      <BlockModal openModal={open} onCloseModalCallback={onCloseModal} title={modalTitle}>
        {discountPromotionalText && (
          <Text variant="subtitle1" css={{ mb: '$10', textAlign: 'center' }}>
            {discountPromotionalText}
          </Text>
        )}
        <CustomFlex justify="between">
          <Button
            variant="primary"
            label={cancelSubscriptionCta}
            buttonState={callInProgress ? 'waiting' : 'default'}
            fullWidth={true}
            css={{ mr: '$2', borderRadius: '$3' }}
            onClick={onCancelSubscriptionClick}
          />
          {applyDiscountApiUrl && (
            <Button
              variant="primary"
              label="Yes, Apply Discount Now"
              fullWidth={true}
              css={{ borderRadius: '$3' }}
              onClick={onApplyDiscountBtnClick}
            />
          )}
        </CustomFlex>
        {failedToCancel && (
          <Text variant="caption" css={{ color: '$ale', textAlign: 'center', mb: '$2' }}>
            Failed to cancel the subscription. Please try again later.
          </Text>
        )}
        {failedToApply && (
          <Text variant="caption" css={{ color: '$ale', textAlign: 'center', mb: '$2' }}>
            Failed to apply the discount. Please try again later.
          </Text>
        )}
      </BlockModal>

      <BlockModal
        openModal={confirmDiscountModalOpen}
        onCloseModalCallback={setConfirmDiscountModalOpen}
        title="Your Discount Has Been Applied.">
        {discountApplicationSuccessText && (
          <Text css={{ color: '$gs12', mb: '$10', textAlign: 'center' }} variant="subtitle1">
            {discountApplicationSuccessText}
          </Text>
        )}
        <Link href="/account">
          <Button variant="primary" label="Go Back To My Account" fullWidth={true} />
        </Link>
      </BlockModal>
    </>
  )
}
