'use client'

import { Box } from '@design-system/src/components/Box'
import { ButtonProps } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { validEmail } from '@design-system/src/utils/validation-utils'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { signInUser } from '.'
import { MagicLinkStateType } from '../../../_utils/typescript-utils'
import { CustomSubmitBtn } from './CustomSubmitBtn'

interface IFormInput {
  email: string
}

export const SignInEmail: FC<{
  magicLinkState: MagicLinkStateType
  setMagicLinkState: React.Dispatch<React.SetStateAction<MagicLinkStateType>>
  setSubmittedEmail: React.Dispatch<React.SetStateAction<string | null>>
}> = ({ magicLinkState, setMagicLinkState, setSubmittedEmail }) => {
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>({ mode: 'onSubmit' })

  const onSubmitForm: SubmitHandler<IFormInput> = async formData => {
    setSubmitBtnState('waiting')
    const email = formData.email
    try {
      await signInUser({ email, type: 'email' })
      setSubmittedEmail(email)
      setMagicLinkState('sent')
    } catch (error) {
      console.error('error signin in:', error)
      setSubmitBtnState('default')
      setMagicLinkState('failed')
    }
  }

  return (
    <Box css={{ mb: '$4' }}>
      <Gutter css={{ paddingLeft: '$0 !important' }}>
        <Text variant="body1" css={{ color: '$gs11' }}>
          Sign in or create your account by entering your&nbsp;email.
        </Text>
      </Gutter>
      <form method="post" onSubmit={handleSubmit(onSubmitForm)}>
        <CustomFlex direction="column" gap={'4'}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Email is required',
              pattern: {
                value: validEmail,
                message: 'Please enter a valid email address.',
              },
            }}
            render={({ field }) => (
              <Textfield
                {...field}
                autoFocus={true}
                fullWidth={true}
                css={{ textAlign: 'center' }}
                placeholder="* Enter your email"
                type="email"
                name="email"
                reactFormErrors={errors}
              />
            )}
          />
          <CustomSubmitBtn disabled={submitBtnState === 'waiting'} label="Sign In" />
        </CustomFlex>
      </form>
      {magicLinkState === 'failed' && (
        <CustomFlex
          css={{ backgroundColor: '$ale', mt: '$4', py: '$2', borderRadius: '$3' }}
          justify="center"
          align="center">
          <Text css={{ color: '$aleText', textAlign: 'center' }} variant="caption">
            The information your entered does not match our records
          </Text>
        </CustomFlex>
      )}
    </Box>
  )
}
