'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import { BlockPubAccountCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { useCallback, useMemo, useState } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'

import {
  getUserSubscription,
  getUserSubscriptionPlan,
  isUserSubscribedToPublication,
} from '../../../../_utils/publication-utils'
import { BlockViewProps } from '../../../../_utils/typescript-utils'
import { AccountPageUI } from '../AccountShared/AccountPageUI'
import { ActiveSubscriptionModal } from '../AccountShared/ActiveSubscriptionModal'
import { DeleteAccountModal } from '../AccountShared/DeleteAccountModal'
import { DeleteAccountSuccessModal } from '../AccountShared/DeleteAccountSuccessModal'
import { ManageAccountModal } from '../AccountShared/ManageAccountModal'

export const AccountPropay: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubAccountCustomPageData
  }>
> = props => {
  const { stripePlans, stripeSupportPlans, propayPlans, connectedSubscriptionSystem, customerServiceNumber } =
    props.blockCustomData
  const { data: session, status } = useSession()
  const [isManageAccountModalOpen, setIsManageAccountModalOpen] = useState<boolean>(false)
  const [isActiveSubscriptionModalOpen, setIsActiveSubscriptionModalOpen] = useState<boolean>(false)
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState<boolean>(false)
  const [isDeleteAccountSuccessModalOpen, setIsDeleteAccountSuccessModalOpen] = useState<boolean>(false)
  const [deleteAccountApiCallInProgress, setDeleteAccountApiCallInProgress] = useState(false)
  const [showAccountFailureErrorMsg, setShowAccountFailureErrorMsg] = useState(false)

  const subscriber = session?.user

  const subscriptionUserPlan = getUserSubscriptionPlan({
    session,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
  })

  const userSubscription = getUserSubscription({ session, connectedSubscriptionSystem })

  const userIsSubscribedToPublication = isUserSubscribedToPublication({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })

  const subscriptionInfo = useMemo<string | undefined>(() => {
    if (
      subscriptionUserPlan?.subscription &&
      subscriptionUserPlan?.system === 'PROPAY' &&
      connectedSubscriptionSystem === 'PROPAY' &&
      userSubscription?.system === 'PROPAY'
    ) {
      const subscriptionName = subscriptionUserPlan.subscription.name
      const subscriptionPriceInCents = userSubscription.subscription?.priceInCents
      const subscriptionPriceFormatted = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        subscriptionPriceInCents / 100,
      )
      const subscriptionFrequency =
        userSubscription.subscription?.paymentFrequency === 'MONTH' ||
        userSubscription.subscription?.paymentFrequency === 'TWENTY_EIGHT_DAYS'
          ? 'Month'
          : 'Year'
      return `${subscriptionName} ${subscriptionPriceFormatted} / ${subscriptionFrequency}`
    }
    return 'No Subscription Selected'
  }, [subscriptionUserPlan, userSubscription])

  // const customerServiceNumberFormatted = customerServiceNumber
  //   ? parsePhoneNumber(customerServiceNumber)?.formatNational()
  //   : ''
  // const customerServiceNumberURI = customerServiceNumber ? parsePhoneNumber(customerServiceNumber)?.getURI() : ''

  const onUserWishesToDeleteAccount = () => {
    if (subscriptionUserPlan) {
      setIsActiveSubscriptionModalOpen(true)
    } else {
      setIsDeleteAccountModalOpen(true)
    }
    setIsManageAccountModalOpen(false)
  }

  const onClickProfile = useCallback(() => {
    setIsManageAccountModalOpen(true)
  }, [])

  return (
    <>
      <AccountPageUI
        {...props.blockCustomData}
        onClickProfile={onClickProfile}
        manageSubscriptionUrl={PAGES_NEXT_PUBLIC_SITES.manageSubscriptionUrl}
        userIsSubscribedToPublication={userIsSubscribedToPublication}
        userEmail={subscriber?.email}
        userIsSubscribedToSupportPlan={subscriptionUserPlan?.type === 'support'}
        subscriptionInfo={subscriptionInfo}
      />

      {/* MANAGE ACCOUNT MODAL */}
      <ManageAccountModal
        modalIsOpen={isManageAccountModalOpen}
        onCloseModalCallback={setIsManageAccountModalOpen}
        onUserWishesToDeleteAccount={onUserWishesToDeleteAccount}>
        <CustomFlex direction={'column'} gap="7">
          {userIsSubscribedToPublication && (
            <CustomFlex direction="column" gap="3">
              <div>
                <Text css={{ pb: '$2', color: '$gs11' }} variant="caption">
                  Information on file:
                </Text>
                <Text variant="body2">
                  {subscriber?.protectpayObfCardInfo?.paymentNetwork} ending in{' '}
                  {subscriber?.protectpayObfCardInfo?.obfCardAccountNumber}
                </Text>
              </div>
              {subscriber?.shippingAddress && (
                <div>
                  <Text css={{ pb: '$2', color: '$gs11' }} variant="caption">
                    Shipping Address:
                  </Text>
                  <CustomFlex direction={'column'} gap="1">
                    <Text variant="body2">{subscriber?.shippingAddress.address1}</Text>
                    <Text variant="body2">{subscriber?.shippingAddress.address2}</Text>
                    <Text variant="body2">
                      {subscriber?.shippingAddress.city}, {subscriber?.shippingAddress.state}{' '}
                      {subscriber?.shippingAddress.zip}
                    </Text>
                    <Text variant="body2">{subscriber?.shippingAddress.country}</Text>
                  </CustomFlex>
                </div>
              )}
            </CustomFlex>
          )}

          <Text variant="body2" css={{ color: '$gs11' }}>
            To update your payment information, please contact customer service
            {customerServiceNumber && (
              <>
                &nbsp;at <a href={`tel:${customerServiceNumber}`}>{customerServiceNumber}</a>
              </>
            )}
            .
          </Text>
        </CustomFlex>
      </ManageAccountModal>

      {/* CAN'T DELETE ACCOUNT --SUBSCRIPTION ACTIVE-- MODAL  */}
      <ActiveSubscriptionModal
        modalIsOpen={isActiveSubscriptionModalOpen}
        onCloseModalCallback={setIsActiveSubscriptionModalOpen}
      />

      {/* DELETE ACCOUNT SUCCESS MODAL */}
      <DeleteAccountSuccessModal
        modalIsOpen={isDeleteAccountSuccessModalOpen}
        onCloseModalCallback={setIsDeleteAccountSuccessModalOpen}
      />

      {/* DELETE ACCOUNT MODAL */}
      <DeleteAccountModal
        modalIsOpen={isDeleteAccountModalOpen}
        showAccountFailureErrorMsg={showAccountFailureErrorMsg}
        setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen}
        setIsDeleteAccountSuccessModalOpen={setIsDeleteAccountSuccessModalOpen}
        setShowAccountFailureErrorMsg={setShowAccountFailureErrorMsg}
      />
    </>
  )
}
